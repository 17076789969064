import * as React from "react"
import Seo from "../components/seo"
import BrandHeading from "../components/brand-heading";
import { Link } from "gatsby";

export default function Contact() {
    return (
        <>
            <Seo title="Privacy Policy" />
            <BrandHeading title="privacy policy" />
            <section className="flex flex-wrap justify-evenly">
                <div className="font-gotu text-justify text-[.78rem] md:text-[0.80rem] tracking-wider md:tracking-[.07em] leading-[1.4rem] md:leading-[1.7rem] text-pas-gray max-w-3xl mx-auto">

                    <h3 className="font-ovo text-left text-xl md:text-2xl tracking-wider text-gray-900 mb-4">SECTION 1 - WHAT DO WE DO WITH YOUR INFORMATION? </h3>
                    <p className="mb-8">
                        When you purchase something from our store, as part of the buying and selling process, we collect the personal information you give us such as your name, contact number, address and email address. When you browse our website, we also automatically receive your computer’s internet protocol (IP) address in order to provide us with information that helps us learn about your browser and operating system. Email marketing (if applicable): With your permission, we may send you emails about our store, new products, discount vouchers and other updates.
                    </p>

                    <h3 className="font-ovo text-left text-xl md:text-2xl tracking-wider text-gray-900 mb-4">
                        SECTION 2 – CONSENT</h3>
                    <h3 className="font-ovo uppercase text-base tracking-wider text-gray-900 mb-3">How do you get my consent?</h3>

                    <p className="mb-6">
                        When you provide us with personal information to complete a transaction, verify your credit card, place an order, arrange for a delivery or return a purchase, we imply that you consent to our collecting it and using it for that specific reason only. If we ask for your personal information for a secondary reason, such as marketing, we will either ask you directly for your expressed consent, or provide you with an opportunity to say no.

                    </p>
                    <h3 className="font-ovo uppercase text-base tracking-wider text-gray-900 mb-3">How do I withdraw my consent? </h3>

                    <p className="mb-8">
                        If after you opt-in, you change your mind, you may withdraw your consent for us to contact you, for the continued collection, use or disclosure of your information, at anytime, by contacting us at admin@pasteleria.co.nz.
                    </p>



                    <h3 className="font-ovo text-left text-xl md:text-2xl tracking-wider text-gray-900 mb-4">SECTION 3 – DISCLOSURE</h3>
                    <p className="mb-8">
                        We may disclose your personal information if we are required by law to do so or if you violate our <Link to="/terms-and-conditions">Terms & Conditions</Link>.
                    </p>

                    <h3 className="font-ovo text-left text-xl md:text-2xl tracking-wider text-gray-900 mb-4">SECTION 4 – AMAZON WEB SERVICES</h3>
                    <p className="mb-6">
                        Our online store/website is hosted on Amazon Web  Services. They provide us with the online platform that allows us to create and manage our own website in order to sell our products and services to you. Your data is stored through Amazon Web Services data storage, databases and the general AWS application. They store your data on a secure server behind a firewall.
                    </p>
                    <h3 className="font-ovo uppercase text-base tracking-wider text-gray-900 mb-3">PAYMENT</h3>

                    <p className="mb-8">
                        When you initially provide or update your payment information, we transmit it via an encrypted connection to our Payment Processor, Stripe. Stripe uses and processes your payment information in accordance with <a href="https://stripe.com/nz/privacy" className="text-pas-orange underline underline-offset-4 decoration-pas-peach-darkest hover:decoration-2">Stripe’s Privacy Policy</a>. We do not store your payment information, other than your address, which we require for billing.


                    </p>

                    <h3 className="font-ovo text-left text-xl md:text-2xl tracking-wider text-gray-900 mb-4">SECTION 5 - THIRD-PARTY SERVICES</h3>

                    <p className="mb-4">
                        In general, the third-party providers used by us will only collect, use and disclose your information to the extent necessary to allow them to perform the services they provide to us.
                    </p>
                    <p className="mb-4">
                        However, certain third-party service providers, such as payment gateways and other payment transaction processors, have their own privacy policies in respect to the information we are required to provide to them for your purchase-related transactions.
                    </p>
                    <p className="mb-8">
                        For these providers, we recommend that you read their privacy policies so you can understand the manner in which your personal information will be handled by these providers.
                    </p>

                    <h3 className="font-ovo text-left text-xl md:text-2xl tracking-wider text-gray-900 mb-4">
                        SECTION 6 – SECURITY</h3>
                    <p className="mb-4">
                        To protect your personal information, we take reasonable precautions and follow industry best practices to make sure it is not inappropriately lost, misused, accessed, disclosed, altered or destroyed.
                    </p>
                    <p className="mb-8">
                        We do not hold your credit card details, we use third party payment provider Stripe, see <a href="https://stripe.com/nz" className="text-pas-orange underline underline-offset-4 decoration-pas-peach-darkest hover:decoration-2">Stripe</a>  website for details.
                    </p>

                    <h3 className="font-ovo text-left text-xl md:text-2xl tracking-wider text-gray-900 mb-4">SECTION 7 – COOKIES</h3>
                    <p className="mb-8">
                        We use these technologies to do things such as remember your preferences, keep you safe and improve and promote the Services. You can read more about how our third party services; <a href="https://aws.amazon.com/legal/cookies/" className="text-pas-orange underline underline-offset-4 decoration-pas-peach-darkest hover:decoration-2">Amazon Web Services</a> and <a href="https://policies.google.com/technologies/partner-sites" className="text-pas-orange underline underline-offset-4 decoration-pas-peach-darkest hover:decoration-2">Google Analytics</a> which is incorporated by reference into this Privacy Policy.
                    </p>

                    <h3 className="font-ovo text-left text-xl md:text-2xl tracking-wider text-gray-900 mb-4">SECTION 8 - CHANGES TO THIS PRIVACY POLICY </h3>

                    <p className="mb-4">
                        We reserve the right to modify this privacy policy at any time, so please review it frequently. Changes and clarifications will take effect immediately upon their posting on the website. If we make material changes to this policy, we will notify you here that it has been updated, so that you are aware of what information we collect, how we use it, and under what circumstances, if any, we use and/or disclose it.
                    </p>
                    <p className="mb-8">
                        If our store is acquired or merged with another company, your information may be transferred to the new owners so that we may continue to sell products to you.
                    </p>

                    <h3 className="font-ovo text-left text-xl md:text-2xl tracking-wider text-gray-900 mb-4">QUESTIONS AND CONTACT INFORMATION</h3>

                    <p className="mb-4">
                        If you would like to: access, correct, amend or delete any personal information we have about you, register a complaint, or simply want more information contact our:
                    </p>
                    <p>Privacy Compliance Officer </p>
                    <p><strong> admin@pasteleria.co.nz</strong> </p>

                </div>
            </section>
        </>
    )
}


